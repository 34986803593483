import { IconButton, Stack, SvgIconTypeMap, useTheme } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Menu, ViewGridOutline } from 'mdi-material-ui';
import { Dispatch, SetStateAction, createElement } from 'react';
import { VigoTooltip } from 'src/@vigo/vigo-tooltip';

interface ViewButtonsProps {
  setViewMode: Dispatch<SetStateAction<'attach' | 'view' | undefined>>;
  viewMode: 'attach' | 'view' | undefined;
}

interface ViewButton {
  viewMode: 'attach' | 'view' | undefined;
  toolTipTitle: string;
  ariaLabel: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
}

const buttons: ViewButton[] = [
  {
    viewMode: 'attach',
    toolTipTitle: 'List',
    ariaLabel: 'list',
    icon: Menu
  },
  {
    viewMode: 'view',
    toolTipTitle: 'Thumbs',
    ariaLabel: 'thumbnails',
    icon: ViewGridOutline
  }
];

const ViewButtons = ({ viewMode, setViewMode }: ViewButtonsProps) => {
  const theme = useTheme();

  return (
    <Stack direction="row">
      {buttons.map((button: ViewButton) => (
        <VigoTooltip key={button.ariaLabel} title={button.toolTipTitle}>
          <IconButton
            color="primary"
            aria-label={button.ariaLabel}
            component="label"
            sx={{
              borderRadius: '6px',
              '&:hover': {
                backgroundColor: theme => theme.palette.customColors.primaryGradient,
                '& > *': { color: '#fff' },
                border: 'none',
                outline: 'none'
              }
            }}
            onClick={() => setViewMode(button.viewMode)}>
            {createElement(button.icon, {
              sx: {
                color:
                  viewMode == button.viewMode
                    ? theme => theme.palette.customColors.primaryGradient
                    : theme.palette.mode === 'light'
                    ? '#626679'
                    : '#fff'
              }
            })}
          </IconButton>
        </VigoTooltip>
      ))}
    </Stack>
  );
};

export default ViewButtons;
