import { useAuth } from 'react-oidc-context';
import { useSettings } from './useSettings';
import { MULTI_BUTTON_PREFIX } from 'src/@vigo/vigo-multiButton';

export const useVigoLogout = () => {
  const { settings, saveSettings } = useSettings();

  const { signoutRedirect } = useAuth();

  /**
   * Removes all customer configuration rules from the local storage.
   *
   * @param {none} - This function does not take any parameters.
   * @return {none} - This function does not return any value.
   */
  const removeCustomerRulesConfig = (): void => {
    Object.keys(localStorage ?? {})
      .filter(key => key.startsWith('CustomerConfig-'))
      .forEach(key => localStorage.removeItem(key));
  };

  const removeVigoMultiButtonState = (): void => {
    Object.keys(localStorage ?? {})
      .filter(key => key.startsWith(MULTI_BUTTON_PREFIX))
      .forEach(key => localStorage.removeItem(key));
  };

  /**
   * Removes the 'account-configurations' item from session storage,
   * removes customer rules configuration, saves settings with an
   * updated current role, and calls the 'signoutRedirect' function.
   *
   * @return {void} This function does not return anything.
   */
  const handleLogout = (): void => {
    sessionStorage.removeItem('account-configurations');
    removeCustomerRulesConfig();
    removeVigoMultiButtonState();
    saveSettings({ ...settings, currentRole: '' as string });

    // remove the session storage for the token
    // removeUser();
    signoutRedirect();
  };

  return { handleLogout };
};
