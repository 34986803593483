import { Stack, useTheme } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { VigoButton } from 'src/@vigo/vigo-button';
import { VigoSelectBox } from 'src/@vigo/vigo-select';
import ViewButtons from './ViewButtons';

interface HeaderProps {
  readOnly: boolean | undefined;
  attachmentTypesAvailable: any[];
  selectedPaperWorkType: string | undefined;
  onPaperWorkChange: any;
  setViewMode: Dispatch<SetStateAction<'attach' | 'view' | undefined>>;
  viewMode: 'attach' | 'view' | undefined;
  openFilePicker: () => void;
  canAttach?: boolean;
}

const Header = ({
  readOnly,
  attachmentTypesAvailable,
  selectedPaperWorkType,
  onPaperWorkChange,
  setViewMode,
  viewMode,
  openFilePicker,
  canAttach = true
}: HeaderProps) => {
  const theme = useTheme();

  return (
    <Stack direction="row">
      <Stack direction="row" sx={{ flex: 1, ...(readOnly && { pointerEvents: 'none' }) }} gap="10px">
        {viewMode === 'attach' && (
          <>
            <VigoSelectBox
              label="Paper Work Type"
              datasource={attachmentTypesAvailable}
              value={attachmentTypesAvailable?.length > 0 ? selectedPaperWorkType : undefined}
              onChange={onPaperWorkChange}
              name={'paperWorkTypeSelectBox'}
              sx={{ width: '230px' }}
            />
            {canAttach && (
              <VigoButton
                name="attachButton"
                value="Attach a File"
                variant="outlined"
                onClick={openFilePicker}
                sx={{
                  textTransform: 'none',
                  paddingInline: '18px',
                  fontSize: '1rem',
                  ...(theme.palette.mode === 'dark' && {
                    color: '#acacac',
                    borderColor: '#626676'
                  }),
                  '&:hover': {
                    backgroundColor: theme => theme.palette.customColors.primaryGradient,
                    color: '#fff',
                    outline: 'none',
                    border: 'none'
                  }
                }}
              />
            )}
          </>
        )}
      </Stack>

      <ViewButtons viewMode={viewMode} setViewMode={setViewMode} />
    </Stack>
  );
};

export default Header;
