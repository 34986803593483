import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useConfirmDialog } from 'src/@core/hooks/useConfirmDialog';
import FilePreview from './components/filePreview';
import ErrorWrapper from './components/ErrorWrapper';
import Header from './components/Header';
import DropZone from './components/dropzone';

export interface VigoAttachment extends File {
  uploaded: boolean;
  uploadProgress: number;
  id: string;
  preview: string;
  thumbnail: string;
  attachmentTypeId: string;
  uploadFile: (mutation: any) => void;
  error: string;
}

interface VigoFileDropProps {
  description: string;
  files: any[];
  entityTypeId: string;
  entityId: string;
  attachmentTypes: any[];
  selectedPaperWorkType?: string;
  onChange: (acceptedFiles: VigoAttachment[], fileRejections: FileRejection[]) => any;
  onPaperWorkChange: (event: any) => any;
  customFileValidator?: (file: VigoAttachment) => {
    code: string;
    message: string;
  } | null;
  onFileDelete?: (file: VigoAttachment) => any;
  onFileUploaded?: (file: VigoAttachment) => any;
  disableUpload?: boolean; // Disable auto upload functionality
  error?: boolean;
  errorMessage?: string;
  maxFileSizeInMB?: number;
  acceptedFileTypes?: Record<string, string[]>;
  required?: boolean;
  readOnly?: boolean; // Disable buttons and select boxes
  disabled?: boolean; // Disable the dropzone
  dropBoxHeight?: string;
  canAttach?: boolean;
}

const VigoFileDrop = React.forwardRef(
  (
    {
      description = '',
      files = [],
      attachmentTypes,
      entityId,
      entityTypeId,
      selectedPaperWorkType,
      onChange,
      customFileValidator,
      onPaperWorkChange,
      onFileDelete,
      onFileUploaded,
      disableUpload = false,
      error = false,
      errorMessage,
      maxFileSizeInMB,
      acceptedFileTypes,
      required = false,
      readOnly,
      disabled,
      dropBoxHeight,
      canAttach = true
    }: VigoFileDropProps,
    ref
  ) => {
    const [viewMode, setViewMode] = useState<'attach' | 'view' | undefined>();

    const { set } = useConfirmDialog();

    // Remove being able to upload pod's and status images for now
    const attachmentTypesAvailable = attachmentTypes.filter(type => !type.isPod && !type.isStatusImage);

    const maxSizeAsBytes = maxFileSizeInMB ? maxFileSizeInMB * 1024 * 1024 : 0;

    const {
      getRootProps,
      getInputProps,
      isDragAccept,
      open: openFilePicker
    } = useDropzone({
      onDrop: onFileDrop,
      validator: fileValidator,
      disabled: disabled,
      ...(maxFileSizeInMB && { maxSize: maxSizeAsBytes }),
      ...(acceptedFileTypes && { accept: acceptedFileTypes })
    });

    /**
     * Handle file drop
     *
     * @param acceptedFiles any
     * @param fileRejections FileRejection[]
     * @param event DropEvent
     */
    function onFileDrop(acceptedFiles: any, fileRejections: FileRejection[]) {
      selectedPaperWorkType !== undefined &&
        acceptedFiles.map((file: VigoAttachment) => (file.attachmentTypeId = selectedPaperWorkType));
      acceptedFiles.map((file: VigoAttachment) => (file.uploaded = false));
      onChange([...files, ...acceptedFiles], fileRejections);
    }

    /**
     * Custom file validator for file dropzone
     *
     * @param file any
     */
    function fileValidator(file: any) {
      if (typeof customFileValidator === 'function') {
        return customFileValidator(file);
      }

      return null;
    }

    /**
     * Handle deleting a file from the dropzone
     *
     * @param index number
     */
    const handleDeleteFile = (file: any, index: number) => {
      set(
        'Are you sure you wish to delete?',
        () => {
          if (typeof onFileDelete === 'function') onFileDelete(file);
          const validIndex = index >= 0 && index <= files.length - 1;

          if (validIndex) {
            const newFileArray = [...files.slice(0, index), ...files.slice(index + 1)];

            if (typeof onChange === 'function') {
              onChange(newFileArray, [] as FileRejection[]);
            }
          }
        },
        undefined,
        'This action cannot be undone'
      );
    };

    /**
     * Handle saving a file from the dropzone
     *
     */
    const handleUploadSuccess = (file: any) => {
      if (typeof onFileUploaded === 'function') onFileUploaded(file);
    };

    useEffect(() => {
      if (files.length > 0) {
        setViewMode('view');
      } else {
        setViewMode('attach');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <ErrorWrapper isError={error} message={errorMessage}>
        <Stack direction="column" height="100%" gap="12px">
          <Header
            {...{
              viewMode,
              setViewMode,
              readOnly,
              attachmentTypesAvailable,
              selectedPaperWorkType,
              onPaperWorkChange,
              openFilePicker,
              canAttach
            }}
          />

          <FilePreview
            {...{
              files,
              viewMode,
              entityTypeId,
              entityId,
              attachmentTypes,
              handleDeleteFile,
              handleUploadSuccess,
              disableUpload,
              readOnly
            }}
          />
          {canAttach && viewMode === 'attach' && (
            <DropZone
              maxFileSize={maxFileSizeInMB}
              {...{
                ref,
                isDragAccept,
                getInputProps,
                getRootProps,
                acceptedFileTypes,
                dropBoxHeight,
                required,
                description
              }}
            />
          )}
        </Stack>
      </ErrorWrapper>
    );
  }
);

export default VigoFileDrop;
