import { useMutation } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { useVigoToast } from 'src/@core/hooks/useVigoToast';
import { BaseModel } from 'src/models/attachment';
import { deleteAttachmentById } from 'src/services/attachment';

export const useAttachmentPanelMutations = () => {
  // Hooks
  const { vigoToast } = useVigoToast({});

  const deleteAttachmentMutation = useMutation({
    mutationFn: async (data: any) => {
      return new Promise(async (resolve, reject) => {
        const deleteAttachmentToastId = vigoToast.loading('Deleting attachment');

        let res: any;

        try {
          const reqBody: AxiosRequestConfig<BaseModel> = {
            data: { version: data.attachmentVersion }
          };

          res = await deleteAttachmentById(data.attachmentDtoId, reqBody);

          vigoToast.success('Attachment deleted', { id: deleteAttachmentToastId });

          return resolve(res);
        } catch (e) {
          vigoToast.error('Error deleting attachment', { id: deleteAttachmentToastId });

          return reject();
        }
      });
    }
  });

  return { deleteAttachmentMutation };
};
